import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Navbar,
  ToggleButtonCheckboxProps,
  Carousel,
  Card,
} from "react-bootstrap";
import "./Promote.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";

const Promote = () => {
  let navigate = useNavigate();
  let [rcolor,setColor] = useState("promote");
  let [data, setData] = useState([]);

  const fetchPromoteData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/features/`);
      const data = await response.json();
      console.log('data',data);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPromoteData();
  }, []);

  return (
    <div className="promote-page">
      <div className="banner-main" style={{ backgroundColor: "#f0f0f0"}}>
        {data
          .filter((item) => item.titles === "About_Promote")
          .map((item) => {
            return (
              <div className="banner d-flex row m-0" style={{ marginTop: "" }}>
                <div className="banner-image-promote-con col-xl-6">
                  <Image className="banner-image-promote" src={item.image} />
                </div>
                <div className="banner-desc col-xl-5 ">
                  <h2 style={{ fontWeight: "500" }}>
                    Promote your <br /> business, service, offer or activity on
                    Digithane.
                  </h2>
                  <p>{item.sub_heading}</p>
                </div>
              </div>
            );
          })}
      </div>
      <Container>
        <div className="listing-process mb-5 d-none d-lg-block">
          {data
            .filter((item) => item.titles === "About_Get_Listed1")
            .map((item) => {
              return (
                <Container>
                  <div className="process d-flex row">
                    <div className="process-heading ">
                      <h1>{item.heading}</h1>
                      <p className="mt-2">{item.sub_heading}</p>
                    </div>
                    <div className="process-image">
                      <Image src={item.image} style={{ height: "90vh" }} />
                    </div>
                    <div className="process-desc text-start col-8 mx-auto">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </Container>
              );
            })}
        </div>
        <div className="listing-process my-5 d-lg-none">
          <div className="process-heading ">
            <h1>Get listed.</h1>
            <p className="mt-2">
              Multiply your reach and visibility at zero extra cost.
            </p>
          </div>
          {
            <Carousel className="my-5" style={{ border: "none" }}>
              {data
                .filter((item) => item.titles === "About_Get_listed")
                .map((item) => {
                  return (
                    <Carousel.Item>
                      <Card style={{ border: "none" }}>
                        <Card.Img
                          variant="top"
                          className="mx-auto"
                          src={item.image}
                          style={{
                            objectFit: "cover",
                            backgroundColor: "#f0f0f0",
                            width: "220px",
                            height: "auto"
                          }}
                        />
                        <Card.Body className="pb-5">
                          <Card.Text>{item.description}</Card.Text>

                        </Card.Body>
                      </Card>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          }
        </div>
        <div className="listing-item d-flex row ">
          {data
            .filter((item) => item.titles === "About_Advertise")
            .map((item) => {
              return (
                <div className="process-listing-item col-xl-6">
                  <div
                    className="process-heading  my-1"
                    style={{ height: "110px" }}
                  >
                    <h1>{item.heading}</h1>
                    <p className="mx-auto col-xl-6">{item.sub_heading}</p>
                  </div>
                  <div className="process-image">
                    <Image
                      className="my-3 ms-3 process-image"
                      src={item.image}
                      style={{ width: "50%", height: "100%" }}
                    />
                  </div>
                  <div className="process-desc  my-4 col-xl-8 mx-auto">
                    <p>{item.description}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </Container>
      <Navbar
        expand="lg"
        className="mt-5 d-none d-lg-block"
        style={{ background: "black", color: "#fff" }}
      >
        <Container fluid>
          <div className="d-flex mx-auto">
            <Navbar.Brand
              className="mx-5"
              href="#home"
              style={{ color: "#fff" }}
            >
              <span>
                <b>Need to know more? </b>
              </span>
              Allow us to call u back
            </Navbar.Brand>
            <button
              className="mx-5"
              style={{
                background: "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)",
                color: "#fff",
                border: "none",
                borderRadius: "15px",
              }}
              onClick={()=>{navigate('/contact');}}
            >
              CONTACT US
            </button>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Promote;
