import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config';
import { Card } from "react-bootstrap"
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

const Places = () => {
    let [places, setPlaces] = useState([]);
    const navigate = useNavigate();
    console.log("places", places);
    const fetchPlaces = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/place/`);
            const data = await response.json();
            setPlaces(data?.data);
 
        } catch (error) {
            console.log(error);
        }
    };
 
    const handleNavigate=(item)=>{
        navigate("/placesdetails/"+ item.id,{state:{item}})
    }
 
    useEffect(() => {
        fetchPlaces()
    }, []);
    return (
        <>
            <h1 style={{marginTop: '30px'}}>Places To Visit</h1>
            <div className=' justify-content-center' style={{height:"100vh", display:"flex", overflowY:"auto", flexGrow:"1", marginTop: '60px'}}>
                {places?.map((item) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
                        <Card className="animate-slide-side Home-Features-card-L mb-4  mx-xl-3 px-0" style={{
                            borderTopLeftRadius: "65px",
                            borderTopRightRadius: "65px",
                            boxShadow:
                                " -7px -7px 20px 0px rgba(0,0,0,0.25),7px 7px 20px 0px rgba(0,0,0,0.25)",
 
                        }}>
                            <Card.Img
                                variant="top"
                                src={BASE_URL + item?.place_image[0]?.image}
                                style={{
                                    width: "100%",
                                    height: "160px",
                                    objectFit: "cover",
                                    borderTopLeftRadius: "25px",
                                    borderTopRightRadius: "25px",
                                }}
                            />
                            <Card.Body className="text-start my-3 ">
                                {/* <Link to="placesdetails "> */}
                                    <Card.Title onClick={()=>handleNavigate(item)} style={{cursor:"pointer"}}> {item.place_name_english}</Card.Title>
                                {/* </Link> */}
                                <Card.Text>{item.place_name_hindi}</Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </>
 
    )
}
 
export default Places