import React,{useState,useEffect} from "react";
import { Container,Image } from "react-bootstrap";
import { BASE_URL } from "../config";
import { Link } from "react-router-dom";
const Footer = () => {
  const [icon,setIcon] = useState([])
  const currentYear = new Date().getFullYear();

  const fetchIcon = async ()=>{
    try{     
      const response = await fetch(`${BASE_URL}/api/features/`)
      const data = await response.json()
      setIcon(data)
    }catch(error){
      // console.log(error)
    }
  }
  useEffect(() => {
   fetchIcon()
  }, [])
  return (
    <div>
    {/* for large screen */}
    <div className="d-none d-lg-block" style={{background: "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)"}}>
      <Container style={{ color: "#ffffff" }}>

        <div className="copyright py-3 d-flex justify-content-around">
          <ul className="my-3">&copy;Copyright {currentYear} Thane,Maharashtra . All rights reserved.</ul>
          <Link className="pt-3 text-white" to="/privacypolicy">Privacy and Policy</Link>
          <div className="follow-us d-flex">
            <h4 className="my-2">Follow Us:</h4>
            {
              icon.filter((item)=>item.titles==='About_ICON').map((item)=>{                
                return(
                  <div key={item.id}>
                    <a href={item.description} target="_blank">
                    <Image className="mx-3" src={item.image} style={{borderRadius:"50%",width:"50px",height:"50px"}}/>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Container>
    </div>
          {/* form mobile screen */}
          <div className="d-lg-none" style={{background: "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)"}}>
      <Container style={{ color: "#ffffff" }}>

        <div className="copyright py-4 ">
          <div className="follow-us mx-4">
            <h4 className="text-start">Follow Us:</h4>
                  <div className=" d-flex">
            {
              icon.filter((item)=>item.titles==='About_ICON').map((item)=>{
                return(
                    <Image className="mx-2" src={item.image} style={{borderRadius:"50%",width:"30px",height:"30px"}}/>
                )
              })
            }
                  </div>
          </div>
          <ul className=" my-3 text-start">{currentYear}@Copyright&copy; {currentYear} Thane, Maharashtra . All rights reserved.</ul>
          <Link className="pt-3 text-white" to="/privacypolicy">Privacy and Policy</Link>
        </div>
      </Container>
    </div>
    </div>
  );
};

export default Footer;
