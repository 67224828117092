import React, { useState, useEffect } from "react";
import { Container, Image, Modal, Accordion, Carousel,Card } from "react-bootstrap";
import './About.css'
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
 

const About = () => {
  let [rcolor,setColor] = useState("about");
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  let [faq, setFaq] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/features/`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFAQ = async () => {
    try {
      
      const response = await fetch(`${BASE_URL}/api/questions/`);
      const data = await response.json();
      setFaq(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchFAQ();
  }, []);

  return (
    <React.Fragment>
      <div className="about_banner ">
        {data
          .filter((item) => item.titles === "About_Banner")
          .map((item) => {
            return (
              <div className="banner" >
                <Image
                className="about-banner-image"
                  src={item.image}
                />
                <div
                  className=" about-text-banner align-item-center"
                >
                  <h1 className="mx-auto text-center">{item.heading}</h1>
                  <div className="banner-text-desc">
                  <p className="mb-5">{item.sub_heading}</p>
                  <a
                    className="px-4 my-5"
                    style={{
                      letterSpacing: "1px",
                      fontWeight: "500",
                      background:
                        "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)",
                        color: "#fff",
                      border: "none",
                      borderRadius: "15px",
                      cursor: "pointer",
                      padding:"5px",
                    }}
                    onClick={()=>{navigate('/promote');}}
                  >
                    PROMOTE YOUR BUSINESS
                  </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {/* </Container> */}
      <Container className="my-5 ">
        {data
          .filter((item) => item.titles === "About_Goal")
          .map((item) => {
            return (
              <div className="about-goal d-xl-flex" style={{ marginTop: "10%" }}>
                <Image className="about-goal-image" src={item.image} />
                <div
                  className="goal-text"
                  style={{ margin: "8%", marginLeft: "0" }}
                >
                  <h1>
                    <b>{item.heading}</b>
                  </h1>
                  <h2 className="mx-auto">{item.sub_heading}</h2>
                  <p className="my-3 p-2">{item.description}</p>
                </div>
              </div>
            );
          })}
      </Container>
      {/* <Container fluid> */}
      <div className="about_banner ">
        {data
          .filter((item) => item.titles === "About_Leadership")
          .map((item) => {
            return (
              <div
                className="banner-leadership"
                style={{ position: "relative", marginTop: "90px" }}
              >
                <Image
                  src={item.image}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "350px",
                    // transform:"scale(1.5)"
                  }}
                />
                <div
                  className=" text-banner-leadership justify-content-center"
                >
                  <h1 className="mx-auto">{item.heading}</h1>
                </div>
                <p
                  style={{
                    marginTop: "40px",
                    margin: "20px",
                    // paddingRight: "13%",
                    // paddingLeft: "13%",
                  }}
                >
                  {item.sub_heading}
                </p>
              </div>
            );
          })}
      </div>
      {/* </Container> */}


        <div className="Leadership ">
          {data
            .filter((item) => item.titles === "About_Backbone")
            .map((item) => {
              return (
                <div
                  className="backbone py-5"
                  style={{
                    marginTop: "70px",
                    background:"#f0f0f0",
                  }}
                >
                  <h1 className="" style={{ color: "#ed4235" }}>
                    <u>{item.heading}</u>
                  </h1>
                  <Image className="m-3" width="100px" src={item.image}  style={{ background:"#f0f0f0" }}/>
                  <p
                  className="px-2"
                    style={{
                      margin: "5px",
                    }}
                  >
                    {item.sub_heading}
                  </p>
                </div>
              );
            })}
        </div>
        <Container>
        <div className="Leadership-directors my-5 d-none d-lg-block d-flex mx-auto">
          <div className="leaders row " style={{width:"100%"}}>
            {data
              .filter((item) => item.titles === "About_Directors")
              .map((item) => {
                return (
                  <React.Fragment>
                    <div className="m-4 offset-2 col-5" >
                      <Image
                        width="240px"
                        height="200px"
                        src={item.image}
                        style={{ borderRadius: "50%" }}
                      />
                      <h5 style={{ marginTop: "15px", marginBottom: "-5px" }}>
                        {item.heading}
                      </h5>
                      <p className="">{`${item.sub_heading.substring(0,300)}`}</p>
                      <button
                        style={{
                          border: "none",
                          color: "#e83e32",
                          fontWeight: "500",
                          backgroundColor:"transparent"
                        }}
                        onClick={() => {
                          setShow(true);
                          setId(item.id);
                        }}
                      >
                        VIEW BIO
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
            {data
              .filter((item) => item.id === id)
              .map((item) => {
                return (
                  <React.Fragment>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className=""
                      backdrop="static"
                      size="xl"
                      keyboard={false}
                      // style={{width:"150px"}}
                    >
                      <Modal.Header
                        style={{ marginBottom: "1px solid black" }}
                        closeButton
                      >
                        {/* <Modal.Title>{item.sub_heading}</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body
                        className=" d-flex justify-content-around"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <Modal.Title className="ml-5 mt-5 my-5 mx-5">
                          {item.heading} <br /> {item.sub_heading}
                        </Modal.Title>
                        <Image
                          width='220px'
                          height='200px'
                          style={{marginBottom:"-15px"}} src={item.image} />
                      </Modal.Body>
                      <Modal.Body className="m-5 pb-5 mb-5">
                        {item.description}
                      </Modal.Body>
                    </Modal>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <div className="Leadership-carousel d-lg-none" style={{background:"#f0f0f0",}}>
          <Carousel  className="my-5" style={{border:"none"}}>
            {data
              .filter((item) => item.titles === "About_Directors")
              .map((item) => {
                return (
                  <Carousel.Item >
                  <Card style={{border:"none"}}>
      <Card.Img variant="top" className="mx-auto" src={item.image} style={{objectFit:"cover",backgroundColor:"#f0f0f0",width:"220px",height:"220px", borderRadius: "50%" }} />
      <Card.Body>
        <Card.Title>{item.heading}</Card.Title>
        <Card.Text>
        {item.sub_heading}
        </Card.Text>
        <button
                        style={{
                          border: "none",
                          color: "#e83e32",
                          fontWeight: "500",
                          backgroundColor:"transparent"
                        }}
                        className='pb-5'
                        onClick={() => {
                          setShow(true);
                          setId(item.id);
                        }}
                      >
                        VIEW BIO
                      </button>
      </Card.Body>
    </Card>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
      </Container>
      <div className="about_banner my-5 d-none d-lg-block">
        {data
          .filter((item) => item.titles === "About_Our_partners")
          .map((item) => {
            return (
              <div className="banner" style={{ position: "relative" }}>
                <Image
                  src={item.image}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "486px",
                  }}
                />
                <div
                  className=" text-banner justify-content-center"
                  style={{
                    position: "absolute",
                    top: "45%",
                    // paddingLeft: "50%",
                    // paddingRight: "5%",
                    left: "40%",
                    color: "#fff",
                  }}
                >
                  <h1 className="mx-auto">{item.heading}</h1>
                </div>
              </div>
            );
          })}
      </div>
      <div className="faq ">
        {data
          .filter((item) => item.titles === "FAQ")
          .map((item) => {
            return (
              <Image
                src={item.image}
                style={{ width: "100%", height: "320px", objectFit: "cover" }}
              />
            );
          })}
        <Container fluid>
          <h1 className="my-5" style={{ color: "red" }}>
            <b>Frequently Asked Questions</b>
          </h1>
          <Accordion className="mx-1 my-5" defaultActiveKey="1" flush>
            <div className="mx-auto faq-container" >
              <Accordion.Item
                className="my-2"
                eventKey="1"
                style={{ borderBottom: "1px solid gray" }}
              >
                <Accordion.Header style={{ color: "#4e4e4e" }}>
                  <h5>
                    1. How long will it take to get listed after uploading the
                    required documents?
                  </h5>
                </Accordion.Header>
                <Accordion.Body
                  style={{ color: "#4e4e4e", fontWeight: "500" }}
                  className="text-start"
                >
                  A. Once all mandatory documents have been uploaded, we will
                  take 7 days to verify the same and get you listed.
                </Accordion.Body>
              </Accordion.Item>
            </div>
            {faq
              .filter((item) => item.id > 1)
              .map((item) => {
                return (
                  <div className="mx-auto faq-container" >
                    <Accordion.Item
                      className="my-2"
                      eventKey={item.id}
                      style={{ borderBottom: "1px solid gray" }}
                    >
                      <Accordion.Header style={{ color: "#4e4e4e" }}>
                        <h5>
                          {item.id}. {item.question_english}
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body
                        className="text-start"
                        style={{ color: "#4e4e4e", fontWeight: "500" }}
                      >
                        A. {item.answer_english}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                );
              })}
          </Accordion>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default About;
