import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Events = () => {
  const navigate = useNavigate();
  let [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/event/`);
      console.log("HomeRes", response);
      const res = await response.json();
      setData(res?.data);      
    } catch (error) {
      console.log("homeerror", error);
    }
  };

  const handleNavigate = (item) => {
    navigate("/eventsdetails/" + item.id, { state: { item } });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <h1 style={{ marginTop: "30px" }}>Events</h1>
      <div
        className=" justify-content-center"
        style={{
          height: "100vh",
          display: "flex",
          overflowY: "auto",
          flexGrow: "1",
          marginTop: "60px",
        }}
      >
        {data?.map((item) => (          
          <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
            <Card
              className="animate-slide-side Home-Features-card-L mb-4  mx-xl-3 px-0"
              style={{
                borderTopLeftRadius: "65px",
                borderTopRightRadius: "65px",
                boxShadow:
                  " -7px -7px 20px 0px rgba(0,0,0,0.25),7px 7px 20px 0px rgba(0,0,0,0.25)",
              }}
            >
              <Card.Img
                variant="top"
                src={BASE_URL + item?.event_image[0]?.image}
                style={{
                  width: "100%",
                  height: "160px",
                  objectFit: "cover",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              />
              <Card.Body className="text-start my-3 ">
                {/* <Link to="eventsdetails "> */}
                <Card.Title
                  onClick={() => handleNavigate(item)}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {item.event_name_english}
                </Card.Title>
                {/* </Link> */}
                <Card.Text>{item.event_name_hindi}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

export default Events;
