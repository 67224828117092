import React  from 'react';
import './App.css';
import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import About from './components/AboutUs/About';
import Contact from './components/Contact/Contact';
import Promote from './Promote/Promote';
import GovernmentServices from './components/GovernmentServices/GovernmentServices';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Events from './components/Events/Events';
import Places from './components/Events/PlacesToVisit/Places';
import PlacesDetail from './components/Events/PlacesToVisit/PlacesDetail';
import EventsDetail from './components/Events/PlacesToVisit/EventsDetail';

function App() {
  return (
    <div className="App">
    <Router>
      <Header/>
      <Routes>
      <Route exact path='/' element={<Home/>} />
      <Route exact path='/about' element={<About/>} />
      <Route exact path='/promote' element={<Promote/>} />
      <Route exact path='/contact' element={<Contact/>} />      
      <Route exact path='/governmentservices' element={<GovernmentServices/>} />      
      <Route exact path='/events' element={<Events/>} />      
      <Route exact path='/places' element={<Places/>} />      
      <Route exact path='/placesdetails/:id' element={<PlacesDetail/>} />      
      <Route exact path='/eventsdetails/:id' element={<EventsDetail/>} />      
      <Route exact path='/privacypolicy' element={<PrivacyPolicy/>} />      
      </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
