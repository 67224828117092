import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config";
import { Card, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Image/government_logo.png";

const GovernmentServices = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/features/`);
      //   console.log("HomeRes",response)
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.log("homeerror", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center">
        <Image src={Logo} alt="governmentLogo" style={{ height: "110px" }} />
        <h1 style={{ fontWeight: "500" }} className="m-4">
          Government Services
        </h1>
      </div>
      <hr style={{ border: "1px solid gray" }}></hr>
      <Container>
        <div className="row justify-content-center">
          {data
            .filter((item) => item.titles === "government_services")
            .map((item) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={item.id}>
                <Card
                  className="mx-1 my-1"
                  style={{
                    borderRadius: "25px",
                    border: "none",
                  }}
                >
                  <Link
                    to={item.sub_heading}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Card.Img
                      className="d-flex mx-auto"
                      variant="top"
                      src={item.image}
                      style={{
                        width: "55%",
                        height: "180px",
                        objectFit: "cover",
                        borderRadius: "25px",
                        boxShadow:
                          "-5px -5px 10px 0px rgba(0,0,0,0.10),5px 5px 10px 0px rgba(0,0,0,0.10)",
                      }}
                    />
                  </Link>
                  <Card.Body className="text-center my-2 px-0">
                    <Card.Text style={{ fontWeight: "500" }}>
                      <Link
                        to={item.sub_heading}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.heading}
                      </Link>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default GovernmentServices;
