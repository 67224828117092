import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config';
import {Link, useLocation} from "react-router-dom";
import {Card} from "react-bootstrap"

const EventsDetail = () => {
    const {state} = useLocation();
    const {item} = state;
    console.log(item);
    const [animationDelay, setAnimationDelay] = useState(0);

    useEffect(() => {
        const delayInterval = 0.2;
        document.querySelectorAll('.fadeIn').forEach((card, index) => {
        card.style.animationDelay = `${animationDelay + index * delayInterval}s`;
        });
    }, []);

  return (
    <div >
        <h1 style={{paddingTop:"10px"}}>Description</h1>
        <hr style={{ border: "1px solid gray" }}></hr>
        <div>
            <div>
                {item?.place_image?.map((newitem)=>{
                  return(
                    //   <img src={`${BASE_URL}/${newitem.image}`} alt="Place Image" />
                      <Card.Img
                                variant="top"
                                src={BASE_URL + newitem.image}
                                style={{
                                    width: "250px",
                                    height: "260px",
                                    marginTop:"40px",
                                    marginRight:"20px",
                                    marginBottom:"20px",
                                    borderRadius: "40px",
                                    boxShadow:"-7px -7px 20px 0px rgba(0,0,0,0.25),7px 7px 20px 0px rgba(0,0,0,0.25)",
                                    animationDelay: `${animationDelay}s`
                                }}
                                className="fadeIn"
                            />
                  )
                })}
            </div>
            <h5>{item?.category}</h5>
            <h5>Location : <Link to={item.url} target='_blank' rel="noopener noreferrer">{item?.url}</Link></h5>
            <h5 style={{padding:"20px 40px" }}>{item.content_english}</h5>
            <hr></hr>
        </div>
    </div>
  )
}

export default EventsDetail