import React from 'react'
 
const PrivacyPolicy = () => {
    return (
        <>
        <div style={{
            backgroundColor: "#3270c2",
            color: "#fff",
            padding: "6px 12px",
            display: "flex",
            fontSize: "18px",
 
            justifyContent:"center",
            marginBottom: "",
    }}>Privacy Policy
    </div>
        <div style={{padding:"0px 20px"}}>
            <hr style={{ border: "1px solid  #3270c2" }}></hr>
            <p style={{marginBottom:"10px", fontWeight:"550", fontSize:"20px" , display:"flex" }} >Registration Information:</p>
            <p style={{textAlign:"start"}}>
            When you sign up with thanesmartcity.in, you become a registered user of the site. You will be asked to provide details such as your name, address, phone/fax number, email address and other personal information as well as information about your business and services.
            </p>
            <p style={{textAlign:"start"}}>
            We may use your Registration Information and/or the Collected Information to provide services that you request and for the purpose of carrying out promotional and business activities by the Thane municipal corporation or any of its other products and any other marketing agent/s and/or contractors with whom the Company enters, or has entered into any arrangement, in connection with providing of services/products, including without limitation, promotion of various business products. Specifically, we may use your email address, mailing address, phone number or fax number to contact you regarding notices, surveys, product alerts, new service or product offerings and communications relevant to your use of our Site. We may generate reports and analysis based on the Registration Information for internal analysis, monitoring and marketing decisions.
            </p>
            <p style={{textAlign:"start"}}>If you submit any information to thanesmartcity.in, we will publish it on the site and this is taken as your consent.</p>
            <p  style={{marginBottom:"10px",marginTop:"50px", fontWeight:"550", fontSize:"20px", display:"flex"}}>
            Statistical Information:
            </p>
            <p style={{textAlign:"start", marginBottom:"50px"}}>As a policy, we also collect aggregate statistical information about your usage such as IP addresses, browser software, operating systems, pages viewed, number of sessions etc. This helps us to understand your usage patterns so we can enhance your experience at thanesmartcity.in web portal. We use Statistical Information to help diagnose problems with and maintain our computer servers, to manage our Site, and to enhance our Site and services based on the usage pattern data we receive. We may generate reports and analysis based on the Statistical Information for internal analysis, monitoring and marketing decisions. We may provide Statistical Information to third parties, but when we do so, we do not provide personally-identifying information without your permission.</p>
        </div>
        </>
    )
}
 
export default PrivacyPolicy