import React, { useState, useEffect } from "react";
import { Container, Image, Card, Carousel } from "react-bootstrap";
import "./Home.css";
import { BASE_URL } from "../../config";

const Home = () => {
  let [data, setData] = useState([]);
  let [otherFeature, setOtherFeature] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/features/`);
      console.log("HomeRes",response)
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.log("homeerror",error);
    }
  };

  const fetchFeatures = async () => {
    try {
      
      const response = await fetch(`${BASE_URL}/api/other-features/`);
      
      const data = await response.json();
      setOtherFeature(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFeatures();
  }, []);

  return (
    <React.Fragment>
      {/* Craousal for big screen */}
      <div className="home-carousel d-none d-lg-block">
        <Carousel>
          {data
            .filter((item) => item.titles === "Home_Carousel")
            .map((item) => {
              console.log('item',item);
              return (
                <Carousel.Item style={{ height: "90vh" }}>
                  <img
                    className="d-block w-100"
                    src={item.image}
                    alt="First slide"
                  />
                  <Carousel.Caption style={{ color: "black" }}>
                    <h1>
                      <b>{item.heading}</b>
                    </h1>
                    <p>{item.sub_heading}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
      {/* Small Screen Home Banner*/}
      <div className="small-carousel d-lg-none mb-5">
        {data
          .filter((item) => item.titles === "Home_Banner_mobile")
          .map((item) => {
            return (
              <div className="mobile-banner">
                <Image
                  src={item.image}
                  style={{ width: "100%", objectFit: "cover" }}
                />
                <h1 className="mx-5 px-5">{item.heading}</h1>
                <p className="mx-5 px-3">{item.sub_heading}</p>
              </div>
            );
          })}
      </div>
      {/* Part 2 */}
      <div className="pb-5" style={{ background: "#f0f0f0" }}>
        <Container>
          <div className="home-features row my-5 mx-auto justify-content-center">
            <div className="my-5">
              <h1 className="mt-5">
                <b>What is Digithane?</b>
              </h1>
              <p className="px-5">
                It's the fastest way to multiply brand visibility, reach and
                revenues.
              </p>
            </div>
            {data
              .filter((item) => item.titles === "Home_Features")
              .map((item) => {
                return (
                  <React.Fragment>
                    {/* for large screen */}
                    <Card
                      className="animate-slide-up Home-Features-card-L mb-4 mx-xl-3 px-0 d-none d-lg-block"
                      style={{
                        minHeight: "350px",
                        boxShadow:
                          "-7px -7px 20px 0px rgba(0,0,0,0.25),7px 7px 20px 0px rgba(0,0,0,0.25)",
                        borderRadius: "25px",
                        border: "none",
                      }}
                      key={item.id}
                    >
                      <Card.Img
                        variant="top"
                        src={item.image}
                        style={{
                          width: "100%",
                          height: "160px",
                          objectFit: "cover",
                          borderRadius: "25px 25px 0 0",
                        }}
                      />
                      <Card.Body className="text-start my-3">
                        <Card.Title>{item.heading}</Card.Title>
                        <Card.Text>{item.sub_heading}</Card.Text>
                      </Card.Body>
                    </Card>
                    {/* for mobile screen */}
                    <Card
                      className="Home-Features-card my-4 mx-auto px-0 d-lg-none"
                      style={{
                        maxWidth: "320px",
                        height: "auto",
                        boxShadow:
                          " -7px -7px 20px 0px rgba(0,0,0,0.25),7px 7px 20px 0px rgba(0,0,0,0.25)",
                        borderRadius: "25px",
                        border: "none",
                        fit: "cover",
                      }}
                      key={item.heading}
                    >
                      <Card.Img
                        variant="top"
                        src={item.image}
                        style={{
                          width: "100%",
                          height: "160px",
                          objectFit: "cover",
                          borderRadius: "25px 25px 0 0",
                        }}
                      />
                      <Card.Body className="text-start my-1">
                        <Card.Title>{item.heading}</Card.Title>
                        <Card.Text className="py-2" style={{ height: "auto" }}>
                          {item.sub_heading}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </React.Fragment>
                );
              })}
          </div>

          <div className="home-features row my-5 mx-auto justify-content-center d-none">
            <div className="my-5">
              <h1 className="mb-5">
                <b>Other Features</b>
              </h1>
              <div className="row">
                {otherFeature.map((item) => {
                  return (
                    <React.Fragment>
                      <div className="col-lg-2">
                        <div className="card-title  text-start">
                          {item.heading}
                        </div>

                        <ul className="ps-3">
                          {item.sub_title.map((subTitle) => {
                            return (
                              <React.Fragment>
                                <li className="card-text text-start">
                                  {subTitle.list_item}
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </ul>
                      </div>
                    </React.Fragment>   
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* for large screen */}
      <div className="home-banner my-5 d-none d-lg-block">
        {data
          .filter((item) => item.titles === "Home_Banner")
          .map((item) => {
            return (
              <div
                className="head-banner"
                key={item.id}
                style={{ position: "relative" }}
              >
                <Image
                  src={item.image}
                  style={{ width: "100%", height: "386px", objectFit: "cover" }}
                />
                <div
                  className="text-banner"
                  style={{
                    position: "absolute",
                    top: "40%",
                    right: "30%",
                    color: "#fff",
                  }}
                >
                  <h1>{item.heading}</h1>
                  <h5 style={{ marginTop: "-6px" }}>{item.sub_heading}</h5>
                </div>
              </div>
            );
          })}
      </div>
      {/* for small screen */}
      <div className="home-banner  d-lg-none">
        {data
          .filter((item) => item.titles === "About_Home_Banner_2_mobile")
          .map((item) => {
            console.log('item',item);
            return (
              <div
                className="head-banner"
                key={item.id}
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Image
                  src={item.image}
                  style={{ width: "100%", height: "286px", objectFit: "cover" }}
                />
                <div
                  className="text-banner px-5 mx-auto"
                  style={{
                    position: "absolute",
                    top: "25%",
                    color: "#fff",
                  }}
                >
                  <h1>{item.heading}</h1>
                </div>
                <h5 className="mt-5 mx-4" style={{ color: "#4e4e4e" }}>
                  {item.sub_heading}
                </h5>
              </div>
            );
          })}
      </div>
      <Container>
        <div className="home-sub-banner row justify-content-center my-5 py-2">
          {data
            .filter((item) => item.titles === "Home_sub_Banner")
            .map((item) => {
              return (
                <Card
                  className="col-6 mx-5 my-3"
                  style={{
                    borderRadius: "25px",
                    width: "402px",
                    border: "none",
                  }}
                  key={item.id}
                >
                  <Card.Body>
                    <Card.Title
                      className="text-start d-none d-lg-block"
                      style={{ fontWeight: "500" }}
                    >
                      {item.heading}
                    </Card.Title>
                    <Card.Title
                      className=" d-lg-none"
                      style={{ fontWeight: "500" }}
                    >
                      <h2>{item.heading}</h2>
                    </Card.Title>
                    <Card.Img
                      variant="top"
                      className="my-3"
                      src={item.image}
                      style={{
                        width: "100%",
                        height: "220px",
                        boxShadow: "1px 0px 1px  #9E9E9E",
                        borderRadius: "25px",
                        objectFit: "cover",
                      }}
                    />
                    <Card.Text className="text-start">
                      {item.sub_heading}
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
        </div>
      </Container>
      {/* for large screen */}
      <div className="home-banner  d-none d-lg-block">
        {data
          .filter((item) => item.titles === "Home_Support")
          .map((item) => {
            return (
              <div
                className="head-banner"
                key={item.id}
                style={{ position: "relative" }}
              >
                <Image
                  src={item.image}
                  style={{ width: "100%", height: "386px" }}
                />
                <div
                  className="text-banner"
                  style={{
                    position: "absolute",
                    top: "37%",
                    objectFit: "cover",
                    right: "26%",
                    color: "#fff",
                  }}
                >
                  <h1>{item.heading}</h1>
                </div>
                <p
                  className="mx-auto my-5"
                  style={{ width: "55%", fontWeight: "500" }}
                >
                  {item.sub_heading}
                </p>
              </div>
            );
          })}
      </div>
      <Container className="d-none d-lg-block">
        {data
          .filter((item) => item.titles === "home_background")
          .map((item) => {
            return (
              <div
                className="home-sub-banner row justify-content-center"
                style={{
                  marginTop: "80px",
                  backgroundImage: " url(${item.image})",
                }}
              >
                {data
                  .filter((item) => item.titles === "Home_sub_support")
                  .map((item) => {
                    return (
                      <Card
                        className="col-4 mx-5 my-3"
                        style={{
                          borderRadius: "25px",
                          width: "250px",
                          border: "none",
                        }}
                        key={item.id}
                      >
                        <Card.Img
                          className="d-flex mx-auto"
                          variant="top"
                          src={item.image}
                          style={{
                            width: "180px",
                            height: "180px",
                            boxShadow: "1px 0px 1px  #9E9E9E",
                            objectFit: "cover",
                            // transform: "scale(1.5)",
                            borderRadius: "25px",
                          }}
                        />
                        <Card.Body className="text-center my-3">
                          <p
                            style={{
                              fontWeight: "500",
                              width: "220px",
                              marginLeft: "-20px",
                            }}
                          >
                            {item.heading}
                          </p>
                        </Card.Body>
                      </Card>
                    );
                  })}
              </div>
            );
          })}
      </Container>
      {/* for small screen */}
      <div className="home-banner d-lg-none">
        {data
          .filter((item) => item.titles === "About_Home_Banner_3_mobile")
          .map((item) => {
            return (
              <div
                className="head-banner"
                key={item.id}
                style={{ position: "relative" }}
              >
                <Image
                  src={item.image}
                  style={{ width: "100%", height: "286px" }}
                />
                <div
                  className="text-banner mx-5"
                  style={{
                    position: "absolute",
                    top: "18%",
                    objectFit: "cover",
                    // right: "26%",
                    color: "#fff",
                  }}
                >
                  <h1>{item.heading}</h1>
                </div>
                <p
                  className="mx-auto my-5"
                  style={{ width: "80%", fontWeight: "500" }}
                >
                  {item.sub_heading}
                </p>
              </div>
            );
          })}
      </div>
      <Container className="h d-lg-none">
        {data
          .filter((item) => item.titles === "home_background")
          .map((item) => {
            return (
              <div
                className="home-sub-banner row d-flex mx-auto"
                style={{ marginTop: "80px" }}
              >
                {data
                  .filter(
                    (item) => item.titles === "Home_sub_support" && item.id < 21
                  )
                  .map((item) => {
                    return (
                      <Card
                        className="col-6 mx-1 my-1"
                        style={{
                          borderRadius: "25px",
                          width: "160px",
                          border: "none",
                        }}
                        key={item.id}
                      >
                        <Card.Img
                          className="d-flex mx-auto"
                          variant="top"
                          src={item.image}
                          style={{
                            width: "120px",
                            height: "120px",
                            boxShadow: "1px 0px 1px  #9E9E9E",
                            objectFit: "cover",
                            borderRadius: "25px",
                          }}
                        />
                        <Card.Body className="text-center my-2 px-0">
                          <Card.Text style={{ fontWeight: "500" }}>
                            {item.heading}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
              </div>
            );
          })}
      </Container>
    </React.Fragment>
  );
};

export default Home;
