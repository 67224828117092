import React ,{useState,useEffect} from "react";
import { Container, Navbar, Nav ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import digithane from '../Image/logo1.png';
import digilogo from '../Image/digilogo.png';
import smartcity from '../Image/smart-citi-Mission-1.png';
import tmclogo from '../Image/TMC-Logo-1.png';
import smartcities from '../Image/smart-city-2.png'
import smart from '../Image/Image.jpg';

const Header = () => {
  let [rcolor,setColor] = useState("home")
  let [logo,setLogo] = useState([])
  let navigate = useNavigate()

  const fetchLogo = async ()=>{
    try{      
      const response = await fetch(`${BASE_URL}/api/features/`);
      const data = await response.json()     
      setLogo(data)
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    fetchLogo()
  }, [])

  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg"
          style={{background: "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)"}}>
          <Container fluid>
          <Navbar.Brand href="/">
            {/* {logo.filter((item)=>item.titles==='Digitown_Logo').map((item)=>{
              console.log('item',item);
               return (
                <Image className='mx-5' src={item.image} style={{height:"60px"}}/>
                )
            })} */}
            <Image className='mx-3' src={digithane} style={{height:"60px", borderRadius: '5px'}}/>
            {logo.filter((item)=>item.titles==='smart_city').map((item)=>{
              console.log('item',item);
              return (
                <Image className='mx-3' src={item.image} style={{height:"55px", borderRadius: '2px'}}/>
                )
            })}
            {/* <Image className='mx-4' src={smart} style={{height:"60px",}}/> */}
          </Navbar.Brand>            
          <Navbar.Toggle aria-controls="navbarScroll"/>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto me-auto"
                style={{color:"#fff"}}
                navbarScroll
              >
              <Nav.Link style={ rcolor==="home"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/'); setColor("home")}} className="nav-link mx-2" >Home</Nav.Link>
              <Nav.Link style={ rcolor==="about"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/about'); setColor("about")}} className="nav-link mx-2">About DigiThane</Nav.Link>
              <Nav.Link style={ rcolor==="promote"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/promote'); setColor("promote")}} className="nav-link mx-2">Promote Your Business</Nav.Link>
              <Nav.Link style={ rcolor==="events"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/events'); setColor("events")}} className="nav-link mx-2">Events</Nav.Link>
              <Nav.Link style={ rcolor==="places"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/places'); setColor("places")}} className="nav-link mx-2">Places</Nav.Link>
              <Nav.Link style={ rcolor==="governmentservices"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/governmentservices'); setColor("governmentservices")}} className="nav-link mx-2">Government Services</Nav.Link>
              <Nav.Link style={ rcolor==="contact"?{color:"#F8D330"}:{color:"#fff"}}  onClick={()=>{navigate('/contact'); setColor("contact")}} className="nav-link mx-2">Contact Us</Nav.Link>
              </Nav>            
            </Navbar.Collapse>
            {logo.filter((item)=>item.titles==='tmc_logo').map((item)=>{              
              return (
                <Image className='mx-4' src={item.image} style={{height:"60px"}}/>
                )
            })}
            {logo.filter((item)=>item.titles==='smart_city_mission').map((item)=>{              
              return (
                <Image className='mx-4' src={item.image} style={{height:"60px", borderRadius: '2px'}}/>
                )
            })}
            {/* <Image className='mx-4' src={tmclogo} style={{height:"60px",}}/> */}
            {/* <Image className='mx-4' src={smartcity} style={{height:"60px",}}/> */}            
            </Container>
        </Navbar>
    </React.Fragment>
  );
};

export default Header;
