import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import "./Contact.css";
import swal from "sweetalert";
import { BASE_URL } from "../../config";

const Contact = () => {

  let [rcolor, setColor] = useState("contact");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [website, setWebsite] = useState("");
  const [details, setDetails] = useState("");
  const [Status, setStatus] = useState(false);

  const allowNumbersOnly = (e) => {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(true);
    let formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("mobile_number", phone);
    formData.append("business_type", businessType);
    formData.append("bbusiness_name", businessName);
    formData.append("business_website", website);
    formData.append("description", details);
    try {
      let res = await fetch(`${BASE_URL}/api/contact_us/`, {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      setStatus(res.status);
      swal({
        text: "Thank you for Connecting with us!",
        icon: "success",
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          setFirstName("");
          setLastName("");
          setPhone("");
          setBusinessType("");
          setBusinessName("");
          setWebsite("");
          setDetails("");
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div className="row">
          <div
            className="my-4 "
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>We are here to help.</h1>
            <li
              className="px-5 mb-3"
              style={{
                listStyle: "none",
                paddingBottom: "70px",
                borderBottom: "1px solid #4e4e4e",
                color: "#4e4e4e",
              }}
            >
              You can leave us your details and allow our Seller Support Team to
              call you, answer your queries and help you get registered.
            </li>
          </div>
          <Form className=" row my-5" onSubmit={handleSubmit}>
            <div className="offset-xl-2 col-xl-5">
              <Form.Group className=" mb-3 d-xl-flex" controlId="formBasicName">
                <Form.Label className="form-label d-none d-lg-block text-start">
                  First Name*
                </Form.Label>
                <p
                  className="d-lg-none text-start "
                  style={{ marginLeft: "20px" }}
                >
                  First Name*
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3 d-xl-flex"
                controlId="formBasicLastName"
              >
                <Form.Label className="form-label d-none d-lg-block text-start">
                  Last Name*
                </Form.Label>
                <p
                  className="d-lg-none text-start"
                  style={{ marginLeft: "20px" }}
                >
                  Last Name*
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3 d-xl-flex"
                controlId="formBasicMobile"
              >
                <Form.Label className="form-label d-none d-lg-block text-start">
                  Mobile Number*
                </Form.Label>
                <p
                  className="d-lg-none text-start"
                  style={{ marginLeft: "20px" }}
                >
                  Mobile Number*
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  required
                  maxLength={10}
                  minLength={10}
                  pattern="^(\+91|\+91\-|0)?[789]\d{9}$"
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 d-xl-flex"
                controlId="formBasicTypeBusiness"
              >
                <Form.Label className="form-label d-none d-lg-block text-start">
                  Type of Business*
                </Form.Label>
                <p
                  className="d-lg-none text-start"
                  style={{ marginLeft: "20px" }}
                >
                  Type of Business*
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group
                className=" mb-3 d-xl-flex"
                controlId="formBasicBusiness"
              >
                <Form.Label className="form-label d-none d-lg-block text-start">
                  Name of Business*{" "}
                </Form.Label>
                <p
                  className="d-lg-none text-start"
                  style={{ marginLeft: "20px" }}
                >
                  Name of Business*{" "}
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto "
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3 d-xl-flex"
                controlId="formBasicBusinessWeb"
              >
                <Form.Label className="form-label d-none d-lg-block text-start">
                  Business Website
                </Form.Label>
                <p
                  className="d-lg-none text-start"
                  style={{ marginLeft: "20px" }}
                >
                  Business Website
                </p>
                <Form.Control
                  className="p-2 form-input mx-auto"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="col-xl-3">
              <Form.Group className="mb-3 d-flex" controlId="formBasicMessage">
                <Form.Control
                  className="p-3 input-message  mx-auto"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  as="textarea"
                  rows={10}
                  placeholder="Message"
                />
              </Form.Group>
              <Button
                className="col-5 col-xl-12 "
                type="submit"
                style={{
                  background:
                    "linear-gradient(90deg, #9F0000 0%, #ED4235 100%)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "15px",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Contact;
